import { useEffect, useRef, useState } from 'react';

const COZE_JS = `https://lf-cdn.coze.cn/obj/unpkg/flow-platform/chat-app-sdk/0.1.0-beta.5/libs/cn/index.js`;

function loadJs(src: string) {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.src = src;
    script.onload = function () {
      resolve(true);
    };
    script.onerror = function (e: any) {
      reject(e);
    };
    document.head.appendChild(script);
  });
}

let result: Promise<unknown>;

function loadCoze() {
  if (!result) {
    result = loadJs(COZE_JS);
  }
  return result;
}

async function mountCozeBot(el?: HTMLElement | null) {
  await loadCoze();
  const CozeWebSDK = (window as any).CozeWebSDK;
  const icon = undefined;
  //   "https://lf-bot-studio-plugin-resource.coze.cn/obj/bot-studio-platform-plugin-tos/artist/image/7e813aa2c7e14ebb9e2d1a989acfb128.png";
  return new CozeWebSDK.WebChatClient({
    config: {
      bot_id: '7401343229893345331',
    },
    componentProps: {
      icon,
      title: 'AI 面试助手',
    },
    el,
  });
}

import { createPortal } from 'react-dom';

export function useCozeBot() {
  const [isOpen, setIsOpen] = useState(false);
  const inst = useRef<any>();
  const portalRef = useRef<HTMLDivElement>(null);
  // TODO 检测元素 size - 动态覆盖 coze 标记
  const portalNode = createPortal(
    <div
      className="_urzhi_coze_container_ fixed right-4 bottom-4 z-[1000]"
      ref={portalRef}
    ></div>,
    document.body,
  );

  useEffect(() => {
    loadCoze();
  }, []);

  async function show() {
    if (!inst.current) {
      setIsOpen(true);
      inst.current = mountCozeBot(portalRef.current);
    }
  }
  async function hide() {
    if (inst.current) {
      setIsOpen(false);
      (await inst.current).destroy?.();
      inst.current = undefined;
    }
  }

  function toggle() {
    if (!inst.current) {
      show();
    } else {
      hide();
    }
  }

  return { portalNode, isOpen, show, hide, toggle };
}
