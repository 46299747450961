export const nano = () => Math.random().toString(36).slice(2, 6);

export function leftPad0(input: string | number, length: number): string {
  return `${'0'.repeat(length)}${input}`.slice(-length);
}

export function safeParse<T = any>(
  input?: string | number | null,
  defaultValue?: T,
): T {
  if (input && typeof input === 'string') {
    try {
      return JSON.parse(input) as T;
    } catch (error) {
      console.debug('[safe parse]', input);
    }
  }
  return defaultValue as T;
}

export function fillId<T extends { _id?: string; [k: string]: any }>(
  items: T[],
) {
  if (items.some((item) => !item['_id'])) {
    return items.map((item) => {
      if (!item._id) {
        return { ...item, _id: nano() };
      }
      return item;
    });
  }
  return items;
}
