import type { DropDownProps, MenuProps } from 'antd';
import { Dropdown, Spin, message } from 'antd';

import { useMutation } from '@tanstack/react-query';
import { useAccess, useModel, useNavigate } from '@umijs/max';
import { FAKE_LOGIN, FAKE_LOGIN_FLAG } from '@urzhi/www/src/constants';
import { ROLE } from '@urzhi/www/src/constants/enum';
import { changeRole } from '@urzhi/www/src/services/v1/session';
import { logout } from '@urzhi/www/src/services/v1/yonghudenglu';
import { track } from '@urzhi/www/src/tracker';
import { setSnappedUserInfo } from '@urzhi/www/src/utils/user';
import { minAsync, wait } from '@urzhi/www/src/utils/wait';
import { useState } from 'react';
import { LoadingText } from '../Button/LoadingText';
import { RelAnchor } from '../Button/RelLink';
import { DefaultHeader } from './DefaultHeader';
import { RoleAvatar } from './RoleAvatar';
import { TopNavItem } from './TopNav';
import { useCozeBot } from './useCozeBot';

// console.info('Dropdown', Dropdown);

export default function (props: { bottom?: React.ReactNode }) {
  const { initialState, loading } = useModel('@@initialState');

  const access = useAccess();

  const cozeBot = useCozeBot();

  const isLogin = Boolean(initialState && initialState?.uid);

  const [open, setOpen] = useState(false);
  const handleOpenChange: DropDownProps['onOpenChange'] = (nextOpen, info) => {
    if (info.source === 'trigger' || nextOpen) {
      setOpen(nextOpen);
    }
  };

  const switchMsgKey = `switch-role`;

  const mutationSwitchRole = useMutation({
    mutationKey: ['switch_role'],
    async mutationFn() {
      message.open({
        key: switchMsgKey,
        type: 'loading',
        content: '切换身份中...',
      });
      const targetRole =
        initialState?.role === ROLE.INTERVIEWER
          ? ROLE.SEEKER
          : ROLE.INTERVIEWER;
      track('switch-role', { to: targetRole });
      await minAsync(
        changeRole({
          role: targetRole,
        }),
        1000,
      );
      location.reload();
      message.destroy(switchMsgKey);
    },
    onError() {
      message.open({
        key: switchMsgKey,
        type: 'error',
        content: `切换失败, 请重试`,
        duration: 2,
      });
    },
  });
  const mutationLogout = useMutation({
    mutationKey: ['logout'],
    async mutationFn() {
      if (FAKE_LOGIN) {
        localStorage.removeItem(FAKE_LOGIN_FLAG);
        await wait(1000);
      } else {
        setSnappedUserInfo(undefined);
        track('logout');
        await logout();
      }
      // 退出登录 - 跳转回登录页面
      location.replace('/user/signin');
      await wait(1500); // 保持动画
    },
  });

  const items: MenuProps['items'] = [
    {
      key: 'name',
      label: (
        <div>
          <div className="font-500">{initialState?.name}</div>
          {/* <div className="text-xs text-gray">{initialState?.telephone}</div> */}
        </div>
      ),
    },
    {
      key: 'switch',
      label: (
        <LoadingText loading={mutationSwitchRole.isPending}>
          <span className="inline-flex gap-1 items-center">
            <span className="text-gray text-xs">
              {initialState?.role === ROLE.INTERVIEWER ? '面试官' : '候选人'}
            </span>
            <span className="text-sm">{' 切换至 → '}</span>
            <span className="text-gray text-xs">
              {initialState?.role === ROLE.INTERVIEWER ? '候选人' : '面试官'}
            </span>
          </span>
        </LoadingText>
      ),
    },
    {
      key: 'switch:coze-bot',
      label: (
        <div
          onClick={() => {
            cozeBot.toggle();
          }}
        >
          {cozeBot.isOpen ? '隐藏答疑助手' : '显示答疑助手'}
        </div>
      ),
    },
    { type: 'divider' },
    {
      key: 'link:/dashboard/profile',
      label: '个人信息',
    },
    { type: 'divider' },
    {
      key: 'link:/dashboard/orders',
      label: '我的面试',
    },
    ...(access.canAccessIncome
      ? [
          {
            key: 'link:/dashboard/wallet',
            label: '我的钱包',
          },
        ]
      : []),
    { type: 'divider' },
    {
      key: 'logout',
      label: (
        <LoadingText loading={mutationLogout.isPending}>
          {'退出登录'}
        </LoadingText>
      ),
    },
  ];
  const navigate = useNavigate();
  const handleMenuClick: Exclude<
    DropDownProps['menu'],
    undefined
  >['onClick'] = (info) => {
    // console.info('menu info', info);
    if (info.key.startsWith('link:')) {
      const to = info.key.replace('link:', '');
      setOpen(false);
      navigate(to);
      track('drop_nav', { to });
      return;
    }
    if (info.key === 'switch') {
      mutationSwitchRole.mutate();
    } else if (info.key === 'logout') {
      mutationLogout.mutate();
    }
    if (info.key === 'name') {
      console.info('identity', initialState);
    }
  };
  return (
    <DefaultHeader
      left={
        <>
          {isLogin ? (
            <>
              {initialState?.role === ROLE.INTERVIEWER ? null : (
                <TopNavItem to="/hermits" data-umami-event="to_interviewers">
                  {'面试官'}
                </TopNavItem>
              )}
              <TopNavItem to="/dashboard/orders" data-umami-event="to_orders">
                {'我的面试'}
              </TopNavItem>
            </>
          ) : (
            <></>
          )}
        </>
      }
      center={
        isLogin ? (
          <span className="text-xs text-neutral-400">
            {initialState?.telephone}
          </span>
        ) : null
      }
      right={
        <>
          {loading ? (
            <Spin></Spin>
          ) : isLogin ? (
            <>
              <Dropdown
                overlayStyle={{ minWidth: '220px' }}
                menu={{ items, onClick: handleMenuClick }}
                open={open}
                onOpenChange={handleOpenChange}
                trigger={['click']}
                placement="bottomRight"
                data-umami-event="head_dropdown"
              >
                <RoleAvatar />
              </Dropdown>
              {cozeBot.portalNode}
            </>
          ) : (
            <>
              <RelAnchor
                href="/user/signin"
                target="_self"
                data-umami-event="to_signin"
                className={
                  'rounded-[32px] py-2 px-6 text-sm text-[#cf6044] bg-[#cf6044]/5 hover:bg-[#cf6044]/10 hover:text-[#cf6044]/80 transition-colors'
                }
              >
                登录
              </RelAnchor>
              <RelAnchor
                href="/user/signup"
                target="_self"
                data-umami-event="to_signup"
                className={
                  'rounded-[32px] py-2 px-6 text-sm text-[#fff] bg-[#cf6044] hover:bg-[#cf6044]/80 hover:text-[#fff]/80 transition-colors'
                }
              >
                注册
              </RelAnchor>
            </>
          )}
        </>
      }
      bottom={props.bottom}
    />
  );
}
